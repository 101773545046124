<template>
	<footer class="container-fluid text-muted small bg-color2">
		<div class="row">
			<nav class="py-3 col-6 col-md-7">
				<ul class="nav">
					<li class="nav-item pr-3 mb-1">
						<a href="https://ufotablewebshop.zendesk.com/hc/ja/articles/4409898422553">ご利用ガイド</a>
					</li>
					<li class="nav-item pr-3 mb-1">
						<a href="https://ufotablewebshop.zendesk.com/hc/ja">よくある質問</a>
					</li>
					<li class="nav-item pr-3 mb-1">
						<a href="/static/tokusho">特定商取引法</a>
					</li>
					<li class="nav-item pr-3 mb-1">
						<a href="/static/riyoukiyaku">利用規約</a>
					</li>
					<li class="nav-item pr-3 mb-1">
						<a href="/static/privacypolicy">プライバシーポリシー</a>
					</li>
					<li class="nav-item text-nowrap">
						<a href="https://webshop-global.ufotable.co.jp/">Global Store(ufotableWEBSHOP-Global) </a>
					</li>
				</ul>
			</nav>
			<div class="sns col-6 col-md-5 py-3 pr-3 pr-md-5 pl-0 text-right">
				<a href="https://twitter.com/ufotable" class="text-center"><img src="/images/icon_twitter.png"></a>
				<a href="https://www.instagram.com/ufotable_inc/" class="text-center"><img src="/images/icon_insta.png"></a>
				<a href="https://www.facebook.com/ufotable/" class="text-center"><img src="/images/icon_fb.png"></a>
			</div>
			<div class="copyright pb-2 pl-3">
				© ufotable
			</div>
			<div id="top-link-block" @click="top()">
				<a id="top-link" class="btn btn-color2 canClick">
					<span class="top-arrow"></span>
				</a>
			</div>
		</div>
	</footer>
</template>

<script>
	export default{
		data(){
			return{
				cart:process.env.VUE_APP_CARTURI,
			}
		},
		methods:{
			top(){
				scrollTo({
					top:0,
					behavior:"smooth"
				});
			}
		}
	}
</script>
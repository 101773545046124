<template>
	<section class="campaignBlock">
		<loading></loading>
		<div v-html="contents">
		</div>
	</section>
</template>


<script>
	import loading from './loading.vue'
	export default{
		components:{
			loading
		},
		data(){
			return{
				path:this.$route.path,
				host:process.env.VUE_APP_IAM,
				s3:process.env.VUE_APP_S3,
				key:this.$route.params.key,
				contents:'',
				date:new Date().getTime()
			}
		},
		created(){
			this.$store.commit('DATA_LOAD', true)
			this.getData()
		},
		mounted(){
			document.body.setAttribute('id',this.key)
			this.setCss()
			this.setJS()
			document.getElementById('scrollTarget').scrollIntoView(true)
			scrollTo(0,0)
		},
		methods:{
			async getData(){
				try{
					const page = await this.$axios.get(this.$uri+'/page/'+this.key)
					document.title = page.data.title + ' / ufotableWEBSHOP'
					this.$gtag.query('config', 'UA-196118927-1', {'page_path': this.path, 'page_title': document.title})
					this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': this.path, 'page_title': document.title})
					this.contents = page.data.body
					this.endLoad()
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			backTop(){
				this.$router.push({'path':'/', 'params':''})
			},
			setCss(){
				let cssBlock = document.createElement('link')

				cssBlock.setAttribute('rel','stylesheet')
				cssBlock.setAttribute('href',this.s3+'/customize.css?v='+this.date)
				document.head.appendChild(cssBlock)
			},
			setJS(){
				let scriptBlock = document.createElement('script')
				
				scriptBlock.setAttribute('src',this.s3+'/customize.js?v='+this.date)
				document.body.appendChild(scriptBlock)
			},
			endLoad(){
				this.$store.commit('DATA_LOAD', false)
			},
		}
	}
</script>
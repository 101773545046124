<template>
	<section class="articleBlock">
		<loading></loading>
		<div class="container">
			<div class="row">
				<div class="col-12 border-bottom pb-2">
					<h5>{{this.newsList.title}}</h5>
					<small>{{this.newsList.date}}</small>
				</div>
				<div class="col-12 py-5" v-html="this.newsList.body"></div>
				<div class="col-12 col-md-9 mt-5 mx-auto">
					<a class="canClick" @click="backList()"><button class="btn btn-color2 btn-block">ニュース一覧へ</button></a>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import loading from './loading.vue'
	export default{
		components:{
			loading
		},
		data(){
			return{
				path:this.$route.path,
				host:process.env.VUE_APP_IAM,
				id:this.$route.params.id,
				pagenum:this.$store.getters.get_pnum,
				newsList:[],
			}
		},
		created(){
			document.title = 'ufotableWEBSHOP'
			this.$gtag.query('config', 'UA-196118927-1', {'page_path': this.path, 'page_title': document.title})
			this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': this.path, 'page_title': document.title})
			this.$store.commit('DATA_LOAD', true)
			this.getData()
		},
		mounted(){
			document.getElementById('scrollTarget').scrollIntoView(true)
			scrollTo(0,0)
		},
		methods:{
			async getData(){
				try{
					const news = await this.$axios.get(this.$uri+'/news/'+this.id)
					this.newsList = news.data
					this.endLoad()
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			backList(){
				this.tagMove({path:'/news/'+this.pagenum, params:{'num':this.pagenum}})
			},
			tagMove(to){
				this.$router.push({'path':to.path, 'params':to.params})
			},
			endLoad(){
				this.$store.commit('DATA_LOAD', false)
			},
		},
	}
</script>
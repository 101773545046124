<template>
	<section class="productsBlock">
		<loading></loading>
		<div class="container">
			<div id="product-category-block" class="row col-12 col-md-10 mx-auto mb-3 mb-md-5 mt-md-5" >
				<div class="col-6 px-2">
					<span>商品種別</span><br>
					<select class="form-control w-100" name="select1" @change="pagemove()" v-model="s_category">
						<option value="0" name="all">すべて</option>
						<option v-for="cat in catPrd" :key="cat.index" :value="cat.name" name="">{{cat.displayName}}</option>
					</select>
				</div>
				<div class="col-6 px-2">
					<span>表示順</span><br>
					<select class="form-control w-100" name="select2" @change="pagemove()" v-model="s_sort">
						<option value="0" name="new">新着順</option>
						<option value="row" name="row">価格の安い順</option>
						<option value="high" name="high">価格の高い順</option>
					</select>
				</div>
			</div>
			<div class="col-12 col-md-10 mx-2 mb-3" v-if="key != null">
				<small>検索ワード：{{key}}</small>
			</div>
			<div class="col-12 mt-5" v-if="isNotfound">
				<p class="text-center">商品が見つかりませんでした</p>
			</div>
			<div class="row" :class="fadeapp ? 'appear':'vanish'">
				<div class="col-6 col-md-4" v-for="prd in disList.slice(minSlice,minSlice+perPage)" :key="prd.index">
					<div class="card mb-4 box-shadow canClick" @click="toDetail(prd)">
						<img class="card-img-top" :src="imageSet(prd.images)" data-holder-rendered="true">
						<div class="card-body">
							<p
								class="stock-status"
								:class="judgeStatus(prd.variations, prd.categories)"
							></p>
							<p class="card-title mb-0 main-point-color">{{prd.title}}</p>
							<p class="card-text d-block">
								{{setPrice(prd.variations)}}円
								<small class="text-nowrap">（税込）</small>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="paging-product" class="mt-5">
			<nav class="nav_pagination mx-auto mt-5" aria-label="ページネーション" :hidden="!isVisible">
				<ul class="pagination justify-content-center">
					<li class="page-item canClick mx-1" :class="isFirst ? 'none':''" @click="preClick()">
						<a class="page-link" aria-label="前へ">
							<span aria-hidden="true" class="pre">«</span>
						</a>
					</li>
					<li v-if="(lastPage>5)&&(curPage>4)" class="cantClick page-link page-dots sp-none"><a><span>…</span></a></li>
					<template v-for="page of lastPage">
						<li class="page-item canClick sp-none mx-1" :class="activeNum(page)" v-if="page>(Number(curPage)-4) && page<(Number(curPage)+4)" :key="page.index" @click="pageClick(page)">
							<a class="page-link"><span>{{page}}</span></a>
						</li>
					</template> 
					<li v-if="(lastPage>5)&&(curPage<(lastPage-3))" class="cantClick page-link page-dots sp-none"><a><span>…</span></a></li>
					<li class="page-item canClick mx-1" :class="isLast ? 'none':''" @click="nextClick()">
						<a class="page-link" aria-label="次へ">
							<span aria-hidden="true" class="next">»</span>
						</a>
					</li>
				</ul>
			</nav>
		</div>
	</section>
</template>

<script>
	import loading from './loading.vue'
	export default{
		components:{
			loading
		},
		data(){
			return{
				host:process.env.VUE_APP_IAM,
				key:this.$route.query['keywords'],
				query:this.$route.query,
				path:this.$route.path,
				prdList:[],
				stkList:[],
				catList:[],
				catPrd:[],
				disList:[],
				s_category:'0',
				s_sort:'0',
				perPage:100,
				curPage:Number,
				minSlice:Number,
				num:Number,
				lastPage:Number,
				fadeapp:false,
				isVisible:true,
				isFirst:false,
				isLast:false,
				isNotfound:false,
				now: new Date()
			}
		},
		created(){
			this.$store.commit('DATA_LOAD', true)
			this.clearOgp()
			this.getCategory()
			this.getData()
		},
		mounted(){
			document.getElementById('scrollTarget').scrollIntoView(true)
			scrollTo(0,0)
		},
		methods:{
			clearOgp(){
				const ogTitle = document.querySelector('meta[property="og:title"]')
				const ogImage = document.querySelector('meta[property="og:image"]')

				if(ogTitle){
					ogTitle.remove()
				}

				if(ogImage){
					ogImage.remove()
				}
			},
			async getData(){
				try{
					const products = await this.$axios.get(this.$uri+'/product')
					const stocks = await this.$axios.get(this.$uri+'/productStock')
					const category = await this.$axios.get(this.$uri+'/category')
					this.catList = category.data.categoryGroups
					this.catPrd = this.catList.find((v)=> v.name == 'category').categories
					this.prdList = products.data.products
					this.stkList = stocks.data
					this.prdList.sort((a,b)=>{
						if(a.validAfter > b.validAfter) return -1
						return 0
					})
					this.sortList()
					this.endLoad()
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			toDetail(item){
				let code = item.variations[0].productCode
				this.$store.commit('CHANGE_ROOT','product')
				this.tagMove({path:'/product/detail/'+code, params:{'hin':code}})
			},
			tagMove(to){
				this.$router.push({'path':to.path, 'params':to.params})
				setTimeout(this.fadeIn,300);
			},
			setPageTitle(){
				if(this.key != void 0){
					document.title = '検索結果：' + this.key + ' / ufotableWEBSHOP'
				}else if(this.$route.query.cat != void 0){
					if(this.$route.query.cat != 0) {
						let pageCategoryArr
						for(var i=0; i<this.catList.length; i++){
							if(this.catList[i].categories.find((v)=> v.name == this.$route.query.cat) != void 0){
								pageCategoryArr = this.catList[i].categories.find((v)=> v.name == this.$route.query.cat)
							}
						}
						if(pageCategoryArr != void 0){
							let pageCategory = pageCategoryArr.displayName
							document.title = pageCategory + ' / ufotableWEBSHOP'
						}else{
							document.title = 'ufotableWEBSHOP'
						}
					}else{
						document.title = 'ufotableWEBSHOP'
					}
				}else{
					document.title = 'ufotableWEBSHOP'
				}
				this.path = this.$route.path + window.location.search
				this.$gtag.query('config', 'UA-196118927-1', {'page_path': this.path, 'page_title': document.title})
				this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': this.path, 'page_title': document.title})
			},
			getCategory(){
				if(this.query.opt !== void 0){
					this.s_sort = this.query.opt
					this.$store.commit('CHANGE_SORT',this.s_sort)
				}else{
					this.s_sort = '0'
					this.$store.commit('CHANGE_SORT',this.s_sort)
				}

				if(this.query.cat !== void 0) {
					this.s_category = this.query.cat
					// this.$store.commit('CHANGE_CATEGORY',this.s_category)
				} else {
					this.s_category = '0'
					// this.$store.commit('CHANGE_CATEGORY',this.s_category)
				}
			},
			fadeIn(){
				this.fadeapp = true
			},
			fadeOut(){
				this.fadeapp = false
			},
			sortList(){
				this.setPageTitle()
				let dnum=0;
				let keySort=[]
				let catSort=[]
				let optSort=[]
				this.disList.splice(-this.disList.length)

				if(this.key != null){
					let keys = this.key.replace(',','|')
					let keyReg = new RegExp(keys, 'i')
					for(let i=0; i<this.prdList.length; i++){
						if(keyReg.test(this.prdList[i].title)){
							keySort.splice(i,0,this.prdList[i])
						}else{
							for(let k=0; k<this.prdList[i].categories.length; k++){
								if(keyReg.test(this.prdList[i].categories[k].displayName)){
									keySort.splice(i,0,this.prdList[i])
								}
							}
						}
					}
				}else{
					for(let k=0; k<this.prdList.length; k++){
						keySort.splice(k,0,this.prdList[k])
					}
				}

				if(this.s_category !== '0') {
					for (let i=0; i<keySort.length; i++) {
						for(let j=0; j<keySort[i].categories.length; j++) {
							let p = keySort[i].categories[j].name
							if (this.s_category==p) {
								catSort.splice(dnum,1,keySort[i])
								dnum++;
							}
						}
					}
				}else{
					for(let k=0; k<keySort.length; k++){
						catSort.splice(k,0,keySort[k])
					}
				}

				for(let k=0; k<catSort.length; k++){
					optSort.splice(k,0,catSort[k])
				}

				if(this.s_sort == 'row'){
					//安い順
					optSort.sort(this.sortAsce)
					for (let i=0; i<optSort.length; i++) {
						this.disList.splice(i,0,optSort[i])
					}
				}else if(this.s_sort == 'high'){
					//高い順
					optSort.sort(this.sortDesc)
					for (let i=0; i<optSort.length; i++) {
						this.disList.splice(i,0,optSort[i])
					}
				}else{
					this.s_sort = 0
					for (let i=0; i<optSort.length; i++) {
						this.disList.splice(i,0,optSort[i])
					}
				}

				if(this.disList.length == 0){
					this.isNotfound = true
				}else{
					this.isNotfound = false
				}

				// this.$store.commit('CHANGE_CATEGORY',this.s_category)
				this.$store.commit('CHANGE_SORT',this.s_sort)
				this.getPageCount()
				this.setPaging()
				this.fadeIn()
			},
			sortDesc(a, b){
				return b.variations[0].price - a.variations[0].price
			},
			sortAsce(a, b){
				return a.variations[0].price - b.variations[0].price
			},
			setPaging(){
				this.num = this.$route.params['num']
				if(isNaN(this.num)){
					this.num = this.$store.getters.get_pnum
				}
				if(this.num <= this.lastPage){
					this.$store.commit('PAGE_MOVE',this.num)
					this.minSlice = (this.num-1)*this.perPage
					this.curPage = this.num;
				}else{
					this.minSlice = 0;
					this.curPage = 1;
					this.$store.commit('PAGE_MOVE',Number(this.curPage))
				}
			},
			pageClick(page){
				this.fadeOut()
				scrollTo(0,20)
				if(page<this.curPage){
					this.curPage=this.curPage-1;
					this.minSlice = (page-1)*this.perPage;
					this.curPage = Number(page);
					this.$store.commit('PAGE_MOVE',this.curPage)
					this.$gtag.query('config', 'UA-196118927-1', {'page_path': '/products/'+page, 'page_title': document.title})
					this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': '/products/'+page, 'page_title': document.title})
					this.tagMove({path:'/products/'+page, params:{}})
				}else if(page>this.curPage){
					this.curPage=this.curPage+1;
					this.minSlice = (page-1)*this.perPage;
					this.curPage = Number(page);
					this.$store.commit('PAGE_MOVE',this.curPage)
					this.$gtag.query('config', 'UA-196118927-1', {'page_path': '/products/'+page, 'page_title': document.title})
					this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': '/products/'+page, 'page_title': document.title})
					this.tagMove({path:'/products/'+page, params:{}})
				}
			},
			preClick(){
				if(!(Number(this.curPage)<=1)){
					this.pageClick(Number(Number(this.curPage))-1);
				}
			},
			nextClick(){
				if(Number(this.curPage)!==this.lastPage){
					this.pageClick(Number(Number(this.curPage))+1);
				}
			},
			activeNum(page){
				if (Number(this.curPage)==1) {
					this.isFirst = true
					this.isLast = false
				}else if(Number(this.curPage)==this.lastPage){
					this.isLast = true
					this.isFirst = false
				}else{
					this.isFirst = false
					this.isLast = false
				}
				if(page==Number(this.curPage)){
					return 'active';
				}
			},
			getPageCount(){
				this.lastPage = Math.ceil(this.disList.length / this.perPage)
				if(this.lastPage<=1){
					this.isVisible=false;
				}else{
					this.isVisible=true;
				}
			},
			imageSet(image_obj){
				if(image_obj[0] === void 0){
					return '/images/nowprinting3.gif'
				}else{
					return image_obj[0].url
				}
			},
			setPrice(v){
				let price = v.map((n)=> n.price)
				let highPrice = this.priceFilter(Math.max.apply(null, price))
				let rowPrice = this.priceFilter(Math.min.apply(null, price))
				if(highPrice == rowPrice){
					return rowPrice
				}else{
					return rowPrice+'～'+highPrice
				}
			},
			judgeStatus(v,categories){
				const release = new Date(v[0].releaseDate)
				let orderTo = new Date()
				if(v[0].reservationTo !== null){
					orderTo = new Date(v[0].reservationTo)
				}
				const customStatus = categories.filter((v)=>v.groupName == 'statusOption')

				const stockList = []
				v.forEach((variation)=> {
					let varFil = this.stkList.filter((c)=>c.productCode == variation.productCode)
					stockList.push(varFil)
				})
				let stockAvailable = 0
				stockList.forEach((s) => {
					if(s[0].available){
						stockAvailable++
					}
				})

				if(this.now > orderTo){
					return 'endofsale'
				}else if(stockAvailable == 0){
					return 'soldout'
				}else if(this.now > release){
					return 'exist'
				}else if(customStatus.length != 0){
					return customStatus[0].name
				}else if(this.now < release){
					return 'btoo'
				}else if(stockAvailable>0){
					return 'exist'
				}else{
					return 'soldout'
				}
			},
			pagemove(){
				this.fadeOut()
				this.$router.push('/products/1?cat='+this.s_category+'&opt='+this.s_sort)
				this.$store.commit('PAGE_MOVE',1)
				this.curPage=1
				scrollTo(0,20)
				setTimeout(this.sortList,300);
			},
			priceFilter(val){
				if (!val) return ''
				return val.toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,')
			},
			endLoad(){
				this.$store.commit('DATA_LOAD', false)
			},
		},
	}
</script>
<template>
	<form class="d-flex align-items-center flex-nowrap mr-2 searchbox" @submit.prevent="keySearch()" name="searchbox">
		<span type="submit" @click="keySearch()"><i class="text-dark fas fa-search mr-1"></i></span>
		<input class="form-control serchtext" name="keyText" type="text" placeholder="検索" v-model="keywords">
	</form>
</template>

<script>
	export default{
		data(){
			return{
				keywords:'',
			}
		},
		methods:{
			keySearch(){
				let space = /\s/g
				let keys
				if(this.keywords != ''){
					keys = this.keywords.replace(space,',')
					.replace(/&/g, '&amp;')
					.replace(/</g, '&lt;')
					.replace(/>/g, '&gt;')
					.replace(/"/g, '&quot;')
					.replace(/'/g, '&#x27')
					window.location.href = '/products?keywords='+keys
				}else{
					window.location.href = '/products'
				}
			},
		},
	}
</script>
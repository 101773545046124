<template>
	<div>
		<section class="detailBlock pb-5">
			<loading></loading>
			<div class="container">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb">
						<li class="breadcrumb-item canClick" @click="toProduct()"><a>商品一覧</a></li>
						<li class="breadcrumb-item canClick" @click="toWorks(works.name)" aria-current="page" v-if="isWorks"><a>{{works.displayName}}</a></li>
						<li class="breadcrumb-item canClick" @click="toProduct(event.name)" aria-current="page" v-if="isEvent"><a>{{event.displayName}}</a></li>
					</ol>
				</nav>
				<div class="row">
					<div class="col-12 text-center align-self-center" v-if="isSP">
						<h4>{{title}}</h4>
					</div>
					<div class="productSlider col-12 col-md-6">
						<div class="prd-slide text-center" v-if="isSingleImg">
							<img :src="imageSet(pList.images)" class="w-100">
						</div>
						<slick class="prd-slide" ref="slick" :options="slickOptions" v-else>
							<a v-for="img in pList.images" :key="img.index">
								<img :src="img.url">
							</a>
						</slick>
						<div class="prd-slide-thumbnail mt-4" ref="thumbnailSlick" v-if="!isSP && !isSingleImg">
							<div class="prd-slide-thumbnail-box" v-for="(img, i) in pList.images" :key="i">
								<a @click="gotoSlide(i)">
									<img :src="img.url">
								</a>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-6 text-center mt-5 mt-md-0 align-self-center" v-if="!isSP">
						<h4>{{title}}</h4>
						<div class="secondary-point-color d-flex flex-nowrap justify-content-center">
							<div v-text="priceFilter(price)"></div><div>円<small>(税込)</small></div>
						</div>
						<hr v-show="isVariation">
						<div class="variation mt-4" v-show="isVariation">
							<div v-for="v in variations" :key="v.index" class="form-check mt-2 mb-4 d-flex">
								<input :id="'radio-'+v.productCode" class="form-check-input" type="radio" :name="v.index" :value="v.productCode" v-model="code" @change="selectVariation(code)">
								<label class="px-1 py-2 form-check-label canClick w-100" :for="'radio-'+v.productCode">{{v.title}}</label>
							</div>

						</div>
						<hr v-show="isVariation">
						<div class="mt-5">
							<div class="row justify-content-end align-items-center" v-if="!isSP">
								<div class="col-4 col-md-4">
									<select v-model="qtySelect" class="form-control w-100">
										<option v-for="(n,index) in qtyBox" :key="index" :value="index+1">{{index+1}}</option>
									</select>
								</div>
								<div class="col-8 col-md-8">
									<input type="button" value="カートへ入れる"  class="btn btn-color cartIn w-100" id="product1_addcart" :disabled="isStocks" @click="cartIn()"/>
								</div>
							</div>
						</div>
						<div v-if="isStocks" class="mt-3">※この商品は現在ご購入いただけません</div>
					</div>
					<div class="item-detail col-12 mt-5" :class="isContent ? '':'spMargin-b'">
						<div class="mx-auto detail-content" ref="detail1">
							<p v-html="description1"></p>
						</div>
						<div v-show="more" class="moreParent"><div class="more canClick" ref="more" @click="toggleDetail('t1')">もっとみる</div></div>
					</div>

					<div class="item-detail col-12" v-show="isContent" ref="detail2">
						<div class="mx-auto detail-content">
							<p v-html="description2"></p>
						</div>
					</div>

					<div class="col-12 col-md-6 text-center align-self-center" v-if="isSP">
						<hr v-show="isVariation">
						<div class="variation mt-4" v-show="isVariation">
							<div v-for="v in variations" :key="v.index" class="form-check mt-2 mb-4 d-flex">
								<input :id="'radio-'+v.productCode" class="form-check-input" type="radio" :name="v.index" :value="v.productCode" v-model="code" @change="selectVariation(code)">
								<label class="px-1 py-2 form-check-label canClick w-100" :for="'radio-'+v.productCode">{{v.title}}</label>
							</div>
						</div>
						<hr v-show="isVariation">
						<div class="secondary-point-color d-flex flex-nowrap justify-content-end my-4">
							<div v-text="priceFilter(price)"></div><div>円<small>(税込)</small></div>
						</div>
						<div class="row justify-content-end align-items-center">
							<div class="col-4 col-md-4">
								<select v-model="qtySelect" class="form-control w-100">
									<option v-for="(n,index) in qtyBox" :key="index" :value="index+1">{{index+1}}</option>
								</select>
							</div>
							<div class="col-8 col-md-8">
								<input type="button" value="カートへ入れる"  class="btn btn-color cartIn w-100" id="product1_addcart" :disabled="isStocks" @click="cartIn()"/>
							</div>
						</div>
						<div v-if="isStocks" class="mt-3">※この商品は現在ご購入いただけません</div>
					</div>
				</div>
			</div>
		</section>
		<section class="productsBlock">
			<div class="container">
				<div class="section-band">新着商品</div>
				<div class="row">
					<div class="col-6 col-md-3" v-for="prd in prdList.slice(0,8)" :key="prd.index">
						<div class="canClick card mb-4 box-shadow" @click="toDetail(prd.variations[0].productCode)">
							<img class="card-img-top" :src="imageSet(prd.images)" data-holder-rendered="true">
							<div class="card-body">
								<p class="stock-status" :class="judgeTagStatus(prd.variations[0].productCode, prd.variations[0].releaseDate, prd.categories)"></p>
								<p class="card-title mb-0 main-point-color">{{prd.title}}</p>
								<p class="card-text d-block">
									{{setPrice(prd.variations)}}円
									<small class="text-nowrap">（税込）</small>
								</p>
							</div>
						</div>
					</div>
					<div class="col-12 mt-4 mx-auto row">
						<button class="col-12 col-md-9 btn btn-color2 btn-block mx-auto" @click="backList()">商品一覧</button>
					</div>
				</div>
			</div>
		</section>
		<section class="productsBlock">
			<div class="container">
				<div class="section-band">ピックアップ商品</div>
				<div class="row">
					<div class="col-6 col-md-3" v-for="prd in pickupList" :key="prd.index">
						<div class="canClick card mb-4 box-shadow" @click="toDetail(prd.variations[0].productCode)">
							<img class="card-img-top" :src="imageSet(prd.images)" data-holder-rendered="true">
							<div class="card-body">
								<p class="stock-status" :class="judgeTagStatus(prd.variations[0].productCode, prd.variations[0].releaseDate, prd.categories)"></p>
								<p class="card-title mb-0 main-point-color">{{prd.title}}</p>
								<p class="card-text d-block">
									{{setPrice(prd.variations)}}円
									<small class="text-nowrap">（税込）</small>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="pickupBlock">
			<div class="container">
				<div class="section-band">販売中のイベントグッズ</div>
				<pickup :data="campList" />
			</div>
		</section>
	</div>
</template>

<script>
	import slick from 'vue-slick'
	import loading from './loading.vue'
	import pickup from './pickupBlockComponent.vue'
	export default{
		components:{
			slick,
			loading,
			pickup
		},
		data(){
			return{
				slickOptions:{
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					dots: true
				},
				pagenum:this.$store.getters.get_pnum,
				path:this.$route.path,
				host:process.env.VUE_APP_IAM,
				cartDomain:process.env.VUE_APP_CARTURI,
				code:this.$route.params.hin,
				codeIndex:Number,
				cartUrl:'/v2/ShoppingCart.aspx?scode=UFWE&pcode=',
				rtnURL:'&rtn=',
				cartInUrl:'',
				campList: [],
				pickupList:[],
				prdList:[],
				pList:[],
				sList:[],
				title:'',
				price:'',
				description1:'',
				description2:'',
				isContent:true,
				isSP:false,
				variations:[],
				isSingleImg:false,
				isVariation:false,
				isStocks:false,
				qtyBox: [],
				qtySelect:1,
				qty: '&qty=',
				root: this.$store.getters.get_rootTransition,
				category: this.$store.getters.get_category,
				sort: this.$store.getters.get_sort,
				works: [],
				isWorks:false,
				event: [],
				isEvent:false,
				more:false,
				year:new Date().getFullYear(),
				month:new Date().getMonth()+1,
				day:new Date().getDate(),
				hour:new Date().getHours(),
				minutes:new Date().getMinutes(),
				seconds:new Date().getSeconds(),
			}
		},
		created(){
			this.handleResize()
			this.$store.commit('DATA_LOAD', true)
			this.getData()
		},
		mounted(){
			window.addEventListener('resize', this.handleResize)
			document.getElementById('scrollTarget').scrollIntoView(true)
			scrollTo(0,0)
		},
		updated(){
			this.chkmore()
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.handleResize)
		},
		methods:{
			setOgp(){
				const title = this.pList.title
				const img = this.imageSet(this.pList.images)
				const head = document.head

				const ogTitle = document.querySelector('meta[property="og:title"]')
				const ogImage = document.querySelector('meta[property="og:image"]')

				if(ogTitle){
					ogTitle.setAttribute('content', title+' / ufotableWEBSHOP')
				}else{
					head.insertAdjacentHTML('beforeEnd', '<meta property="og:title" content="'+title+' / ufotableWEBSHOP">')
				}

				if(ogImage){
					ogImage.setAttribute('content', img)
				}else{
					head.insertAdjacentHTML('beforeEnd', '<meta property="og:image" content="'+img+'">')
				}
			},
			async getData(){
				try{
					const campaign = await this.$axios.get(this.$uri+'/image?key=campaign')
					const products = await this.$axios.get(this.$uri+'/product')
					const product = await this.$axios.get(this.$uri+'/product/'+this.code)
					const stocks = await this.$axios.get(this.$uri+'/productStock')
					this.campList = campaign.data.images
					this.prdList = products.data.products
					this.pickupList = products.data.products.filter((v)=>v.categories.some((n)=>n.groupName=='pickup'))
					this.pList = product.data

					this.setOgp()
					this.sList = stocks.data

					this.works = this.pList.categories.filter((v)=>v.groupName=='works')[0]
					if(this.works){
						this.isWorks = true
					}else{
						this.isWorks = false
					}

					this.event = this.pList.categories.filter((v)=>v.groupName=='campaign')[0]
					if(this.event){
						this.isEvent = true
					}else{
						this.isEvent = false
					}

					this.prdList.sort((a,b)=>{
						if(a.validAfter > b.validAfter) return -1
						return 0
					})

					this.pickupList.sort((a,b)=>{
						if(a.validAfter > b.validAfter) return -1
						return 0
					})
					this.setData()
					this.endLoad()
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			chkmore(){
				let t1 = this.$refs.detail1
				let t2 = this.$refs.detail2
				t2.style.marginTop = '1rem'

				if(t1.offsetHeight > 180){
					this.more = true
					t1.classList.add('toggle')
				}

			},
			chkCategory(val){
				if(val){
					return true
				}else{
					return false
				}
			},
			toggleDetail(t){
				if(t == 't1'){
					let t1 = this.$refs.detail1
					let t2 = this.$refs.detail2
					let more = this.$refs.more
					more.classList.toggle('open')
					t1.classList.toggle('toggle')
					if(t1.classList.contains('toggle')){
						more.textContent = 'もっとみる'
						t2.style.marginTop = '1rem'
					}else{
						more.textContent = '閉じる'
						t2.style.marginTop = '4.5rem'
					}
				}
			},
			gotoSlide(i) {
				this.$refs.slick.goTo(i)
			},
			next() {
				this.$refs.slick.next()
			},
			prev() {
				this.$refs.slick.prev()
			},
			reInit() {
				this.$refs.slick.destroy()
				this.$nextTick(() => {
					this.$refs.slick.create()
				})
			},
			setData(){
				this.variations = this.pList.variations
				this.codeIndex = this.variations.findIndex((v)=> v.productCode == this.code)
				this.title = this.pList.title
				document.title = this.title + ' / ufotableWEBSHOP'
				this.$gtag.query('config', 'UA-196118927-1', {'page_path': this.path, 'page_title': document.title})
				this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': this.path, 'page_title': document.title})
				this.price = this.pList.variations[this.codeIndex].price
				this.description1 = this.pList.descriptions[0]
				this.description2 = this.pList.descriptions[1]
				if(this.description2 == ""){
					this.isContent = false
				}
				if(this.variations.length > 1){
					this.isVariation = true
				}else{
					this.isVariation = false
				}
				this.qtyBox = this.qtyLimit(this.codeIndex)
				if(this.pList.images.length ==0 || this.pList.images.length == 1){
					this.isSingleImg = true
				}else{
					this.reInit()
				}

				this.chkStock(this.variations[this.codeIndex].productCode)

				if(this.root == 'product' || this.root == ''){
					this.rtnURL = this.rtnURL+encodeURIComponent(this.host+'/products/'+this.pagenum+'?cat='+this.category+'&opt='+this.sort)
				}else{
					this.rtnURL = this.rtnURL+encodeURIComponent(this.host+'/works/'+this.works+'/'+this.pagenum+'?cat='+this.category+'&opt='+this.sort)
				}
			},
			selectVariation(v){
				this.codeIndex = this.variations.findIndex((v)=> v.productCode == this.code)
				this.chkStock(v)
				this.qtyBox = this.qtyLimit(this.codeIndex)
				this.price = this.pList.variations[this.codeIndex].price
			},
			imageSet(image_obj){
				if(image_obj[0] === void 0){
					return this.host+'/images/nowprinting3.gif'
				}else{
					return image_obj[0].url
				}
			},
			qtyLimit(v){
				if(v.quantityLimited){
					return v.quantityLimit
				}else{
					return 99
				}
			},
			cartIn(){
				this.cartInURL = this.cartDomain + this.cartUrl + this.code + this.qty + this.qtySelect + this.rtnURL
				window.location.href=this.cartInURL
			},
			backList(){
				if(this.root == 'product' || this.root == ''){
					this.tagMove({path: '/products/'+this.pagenum+'?opt='+this.sort, params:{'num':this.pagenum}})
					// this.tagMove({path:'/products/'+this.pagenum+'?cat='+this.category+'&opt='+this.sort, params:{'num':this.pagenum}})
				}else{
					this.tagMove({path: '/products', params:{}})
					// this.tagMove({path:'/works/'+this.root+'/'+this.pagenum+'?cat='+this.category+'&opt='+this.sort, params:{'num':this.pagenum}})
				}
			},
			toProduct(category){
				if(category) {
					this.tagMove({path:'/products?cat='+category})
				} else {
					this.tagMove({path:'/products'})
				}
			},
			toDetail(item){
				window.location.href='/product/detail/'+item
			},
			toWorks(category){
				this.tagMove({path:'/works/'+category, params:{'name':category}})
			},
			tagMove(to){
				this.$gtag.query('config', 'UA-196118927-1', {'page_path': to.path});
				this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': to.path});
				this.$router.push({'path':to.path, 'params':to.params})
			},
			chkStock(code){
				let av = []
				av.push(this.sList.find((n)=> n.productCode == code))
				let orderTo = new Date()
				const target = this.variations.filter((v)=>v.productCode == code)[0]
				if(target.reservationTo !== null){
					orderTo = new Date(target.reservationTo)
				}
				const now = new Date()
				if(now > orderTo){
					this.isStocks = true
				}else if(av.filter((v)=>v.available==true).length>0){
					this.isStocks = false
				}else{
					this.isStocks = true
				}
			},
			priceFilter(value){
				if (!value) return ''
				return value.toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,')
			},
			setPrice(v){
				let price = v.map((n)=> n.price)
				let highPrice = this.priceFilter(Math.max.apply(null, price))
				let rowPrice = this.priceFilter(Math.min.apply(null, price))
				if(highPrice == rowPrice){
					return rowPrice
				}else{
					return rowPrice+'～'+highPrice
				}
			},
			judgeTagStatus(code,release,categories){
				const customStatus = categories.filter((v)=>v.groupName == 'statusOption')

				if(String(this.day).length==1){
					this.day = '0'+String(this.day)
				}
				if(String(this.month).length==1){
					this.month = '0'+String(this.month)
				}
				if(String(this.hour).length==1){
					this.hour = '0'+String(this.hour)
				}
				if(String(this.minutes).length==1){
					this.minutes = '0'+String(this.minutes)
				}
				if(String(this.seconds).length==1){
					this.seconds = '0'+String(this.seconds)
				}
				const now = String(this.year)+String(this.month)+String(this.day)+String(this.hour)+String(this.minutes)+String(this.seconds)
				const regExp = /[-T:]/g
				const stock = this.sList.find((v)=>v.productCode == code)
				release = release.replace(regExp,'')
				
				if(!stock.available){
					return 'soldout'
				}else if(Number(now)>Number(release)){
					return 'exist'
				}else if(customStatus.length != 0){
					return customStatus[0].name
				}else if(Number(now)<Number(release)){
					return 'btoo'
				}else if(stock.available){
					return 'exist'
				}else{
					return 'soldout'
				}
			},
			handleResize() {
				this.path = this.$route.path
				this.width = window.innerWidth
				if(this.path.indexOf('/login/') != -1){
					this.isSP = false
				}else if(this.width <= 575){
					this.isSP = true
				}else{
					this.isSP = false
				}
			},
			endLoad(){
				this.$store.commit('DATA_LOAD', false)
			},
		},
	}
</script>
//import Vue from 'vue'

const state = {
	pagenum:1,
	category:'0',
	sort:'0',
	loaded:true,
	rootTransition:'product',

}

const mutations = {
	'PAGE_MOVE'(state, val){
		state.pagenum = val
	},
	'CHANGE_CATEGORY'(state, val){
		state.category = val
	},
	'CHANGE_SORT'(state, val){
		state.sort = val
	},
	'DATA_LOAD'(state, val){
		state.loaded = val
	},
	'CHANGE_ROOT'(state, val){
		state.rootTransition = val
	},
};

const actions = {
	set_page:({commit},val) => {
		commit('PAGE_MOVE',val)
	},
	slect_category:({commit},val) => {
		commit('CHANGE_CATEGORY',val)
	},
	select_sort:({commit},val) => {
		commit('CHANGE_SORT',val)
	},
	set_load:({commit},val) => {
		commit('DATA_LOAD', val)
	},
	set_root:({commit},val) => {
		commit('CHANGE_ROOT', val)
	},
};

const getters = {
	get_pnum: state => {
		return state.pagenum
	},
	get_category: state => {
		return state.category
	},
	get_sort: state => {
		return state.sort
	},
	get_loadstatus: state => {
		return state.loaded
	},
	get_rootTransition: state => {
		return state.rootTransition
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
}
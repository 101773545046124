<template>
	<section class="newsBlock">
		<loading></loading>
		<div class="container">
			<div class="section-band">ニュース</div>
			<div class="row">
				<div class="col-12">
					<ul class="list-group-flush pl-0">
						<li class="list-group-item d-flex canClick" v-for="news in newsList.slice(minSlice,minSlice+perPage)" :key="news.index" @click="toArticle(news.id)">
							<a class="d-flex"><div class="mr-4 text-nowrap">{{news.date}}</div><div>{{news.title}}</div></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div id="paging-product" class="mt-5 pb-5">
			<nav class="nav_pagination mx-auto mt-5" aria-label="ページネーション" :hidden="!isVisible">
				<ul class="pagination justify-content-center">
					<li class="page-item canClick mx-1" :class="isFirst ? 'none':''" @click="preClick()">
						<a class="page-link" aria-label="前へ">
							<span aria-hidden="true" class="pre">«</span>
						</a>
					</li>
					<li v-if="(lastPage>5)&&(curPage>4)" class="cantClick page-link px-3 my-2 mx-1 sp-none"><a><span>…</span></a></li>
					<template v-for="page of lastPage">
						<li class="page-item canClick sp-none mx-1" :class="activeNum(page)" v-if="page>(Number(curPage)-4) && page<(Number(curPage)+4)" :key="page.index" @click="pageClick(page)">
							<a class="page-link"><span>{{page}}</span></a>
						</li>
					</template> 
					<li v-if="(lastPage>5)&&(curPage<(lastPage-3))" class="cantClick page-link px-3 my-2 mx-1 sp-none"><a><span>…</span></a></li>
					<li class="page-item canClick mx-1" :class="isLast ? 'none':''" @click="nextClick()">
						<a class="page-link" aria-label="次へ">
							<span aria-hidden="true" class="next">»</span>
						</a>
					</li>
				</ul>
			</nav>
		</div>
		<div class="col-12 col-md-9 mt-5 mx-auto w-50">
			<a class="canClick" @click="backTop()"><button class="btn btn-color2 btn-block">もどる</button></a>
		</div>
	</section>
</template>

<script>
	import loading from './loading.vue'
	export default{
		components:{
			loading
		},
		data(){
			return{
				path:this.$route.path,
				host:process.env.VUE_APP_IAM,
				newsList:[],
				perPage:10,
				curPage:Number,
				minSlice:Number,
				num:Number,
				lastPage:Number,
				fadeapp:true,
				isVisible:true,
				isFirst:false,
				isLast:false,
			}
		},
		created(){
			document.title = 'ufotableWEBSHOP'
			this.$gtag.query('config', 'UA-196118927-1', {'page_path': this.path, 'page_title': document.title});
			this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': this.path, 'page_title': document.title});
			this.$store.commit('DATA_LOAD', true)
			this.getData()
		},
		mounted(){
			document.getElementById('scrollTarget').scrollIntoView(true)
		},
		methods:{
			async getData(){
				try{
					const news = await this.$axios.get(this.$uri+'/news')
					this.newsList = news.data.news
					this.getPageCount()
					this.setPaging()
					this.endLoad()
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			backTop(){
				this.tagMove({path:'/', params:{}})
			},
			toArticle(id){
				this.tagMove({path:'/news/article/'+id, params:{'id':id}})
			},
			tagMove(to){
				this.$router.push({'path':to.path, 'params':to.params})
			},
			setPaging(){
				this.num = this.$route.params['num']
				if(isNaN(this.num)){
					this.num = this.$store.getters.get_pnum
				}
				if(this.num <= this.lastPage){
					this.$store.commit('PAGE_MOVE',this.num)
					this.minSlice = (this.num-1)*this.perPage
					this.curPage = this.num;
				}else{
					this.minSlice = 0;
					this.curPage = 1;
					this.$store.commit('PAGE_MOVE',Number(this.curPage))
				}
			},
			pageClick(page){
				if(page<this.curPage){
					this.curPage=this.curPage-1;
					this.minSlice = (page-1)*this.perPage;
					this.curPage = Number(page);
					this.$store.commit('PAGE_MOVE',this.curPage)
					this.$gtag.query('config', 'UA-196118927-1', {'page_path': '/news/'+page, 'page_title': document.title})
					this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': '/news/'+page, 'page_title': document.title})
					this.tagMove({path:'/news/'+page, params:{}})
				}else if(page>this.curPage){
					this.curPage=this.curPage+1;
					this.minSlice = (page-1)*this.perPage;
					this.curPage = Number(page);
					this.$store.commit('PAGE_MOVE',this.curPage)
					this.$gtag.query('config', 'UA-196118927-1', {'page_path': '/news/'+page, 'page_title': document.title})
					this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': '/news/'+page, 'page_title': document.title})
					this.tagMove({path:'/news/'+page, params:{}})
				}
			},
			preClick(){
				if(!(Number(this.curPage)<=1)){
					this.pageClick(Number(Number(this.curPage))-1);
				}
			},
			nextClick(){
				if(Number(this.curPage)!==this.lastPage){
					this.pageClick(Number(Number(this.curPage))+1);
				}
			},
			activeNum(page){
				if (Number(this.curPage)==1) {
					this.isFirst = true
					this.isLast = false
				}else if(Number(this.curPage)==this.lastPage){
					this.isLast = true
					this.isFirst = false
				}else{
					this.isFirst = false
					this.isLast = false
				}
				if(page==Number(this.curPage)){
					return 'active';
				}
			},
			getPageCount(){
				this.lastPage = Math.ceil(this.newsList.length / this.perPage)
				if(this.lastPage<=1){
					this.isVisible=false;
				}else{
					this.isVisible=true;
				}
			},
			endLoad(){
				this.$store.commit('DATA_LOAD', false)
			},
		},
	}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import top from '../components/top.vue'
import works from '../components/works.vue'
import products from '../components/products.vue'
import detail from '../components/detail.vue'
import article from '../components/article.vue'
import news from '../components/news.vue'
import lp from '../components/lp.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'top',
    component: top
  },
  {
    path: '/works/:name',
    component: works,
    children: [
      {
        path: '/works/:name/:num',
      },
    ]
  },
  {
    path: '/products',
    name: 'products',
    component: products,
    children: [
      {
        path: '/products/:num',
      },
    ]
  },
  {
    path: '/product/detail/:hin',
    component: detail,
  },
  {
    path:'/news',
    component: news,
    children:[
      {
        path: '/news/:num',
      },
    ]
  },
  {
    path: '/news/article/:id',
    component: article,
  },
  {
    path: '/static/:key',
    component: lp,
  },
  {
    path: '/maintenance/',
    redirect: '/maintenance/maintenance.html',
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition){
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  router['referrer'] = from
  next()
})


export default router

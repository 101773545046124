<template>
	<div class="d-flex bg-color2">
		<button class="drawer-toggle drawer-hamburger" type="button">
			<span class="drawer-hamburger-icon"></span>
		</button>
		<nav class="drawer-nav" role="navigation">
			<ul class="drawer-menu mb-3">
				<li class="nav-heading">マイページ</li>
				<li>
					<a class="drawer-menu-item" :href="cart+'/v2/member/MyPage.aspx?ccode=UFWE'">新規会員登録 / ログイン</a>
				</li>
				<li class="nav-heading">商品</li>
				<li>
					<a class="drawer-menu-item" href="#collapseWorks" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="collapseWorks">作品から探す</a>
					<div class="collapse" id="collapseWorks">
						<a class="dropdown-item canClick text-wrap" v-for="cat in catWorks" :key="cat.index" @click="toWorks(cat.name)">{{cat.displayName}}</a>
					</div>
				</li>
				<li>
					<a class="drawer-menu-item" href="#collapseCategory" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="collapseCategory">商品種別から探す</a>
					<div class="collapse" id="collapseCategory">
						<a class="dropdown-item canClick text-wrap" v-for="cat in catPrd" :key="cat.index" @click="toProduct(cat.name)">{{cat.displayName}}</a>
					</div>
				</li>
				<li class="nav-heading">ヘルプ</li>
				<li>
					<a class="drawer-menu-item" href="https://ufotablewebshop.zendesk.com/hc/ja">よくある質問</a>
				</li>
				<li>
					<a class="drawer-menu-item" href="https://ufotablewebshop.zendesk.com/hc/ja/categories/900000421486-%E7%99%BA%E9%80%81%E3%82%B9%E3%82%B1%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB">商品出荷スケジュール</a>
				</li>
			</ul>
			<search class="mx-3"></search>
		</nav>
		<header class="fixed-top d-flex justify-content-between pl-5">
			<div class="d-flex nav-left">
				<a class="drawer-brand" href="/">
					<!-- <object type="image/svg+xml" data="/images/logo.svg" height="60">
						<img src="/images/logo.png" height="60">
					</object> -->
				</a>
			</div>
			<div class="d-flex align-items-center nav-right">
				<search v-if="!isSP"></search>
				<a :href="cart+'/v2/member/MyPage.aspx?ccode=UFWE'" class="pr-2 text-nowrap">
					<button class="btn btn-header btn-cart">
						<i class="fa fa-solid fa-user mx-1 mr-md-1 mx-md-0"></i>
						<span class="sp-none">マイページ</span>
					</button>
				</a>
				<a :href="cart+'/v2/ShoppingCart.aspx?ccode=UFWE'" class="pr-2 text-nowrap">
					<button class="btn btn-header btn-cart">
						<i class="fas fa-shopping-cart mr-1"></i>
						<span class="sp-none">カート</span>
					</button>
				</a>
			</div>
		</header>
		<div class="countrymsg text-center" v-show="noJp">
			<div class="py-2" ref="countryInduction"><p class="mb-0"><a target="_blank" href="https://webshop-global.ufotable.co.jp/">Click here for “ufotableWEBSHOP-Global”. Your region may be available for purchase our merchandise!</a></p></div>
		</div>
	</div>
</template>

<script>
import search from './searchBox'
export default{
	data(){
		return{
			path:this.$route.path,
			cat:this.$route.query['cat'],
			cart:process.env.VUE_APP_CARTURI,
			categories:[],
			catWorks:[],
			catPrd:[],
			isSP:false,
			noJp:false,
		}
	},
	components:{
		search,
	},
	created(){
		this.getCategory()
	},
	mounted(){
		this.handleResize()
		window.addEventListener('resize', this.handleResize)
		this.chkCountry()
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize)
	},
	methods:{
		chkCountry(){
			const lang = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage
			if(lang == 'ja-JP' || lang == 'ja'){
				this.noJp = false
			}else{
				this.noJp = true
			}
		},
		async getCategory(){
			try{
				const category = await this.$axios.get(this.$uri+'/category')
				this.categories = category.data.categoryGroups
				this.catWorks = this.categories.find((v)=> v.name == 'works').categories
				this.catPrd = this.categories.find((v)=> v.name == 'category').categories
			}catch(e){
				// console.log(e)
			}
		},
		toWorks(category){
			this.tagMove({path:'/works/'+category, params:{'name':category}})
		},
		toProduct(category){
			this.tagMove({path:'/products?cat='+category, params:{'cat':category}})
		},
		tagMove(to){
			let bodyClass = document.body.classList
			if(bodyClass.contains('drawer-open')){
				document.getElementsByClassName('drawer-toggle')[0].click()
			}

			if(this.cat != void 0){
				if(this.path+'?cat='+this.cat != to.path){
					this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': to.path});
					this.$router.push({'path':to.path, 'params':to.params})
					this.$router.go({path: this.$router.currentRoute.path, force: true})
					this.path = this.$route.path
					this.cat = this.$route.params.cat
				}
			}else if(this.path != to.path){
				this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': to.path});
				this.$router.push({'path':to.path, 'params':to.params})
				this.$router.go({path: this.$router.currentRoute.path, force: true})
				this.path = this.$route.path
				this.cat = this.$route.params.cat
			}
		},
		handleResize() {
			this.width = window.innerWidth;
			if(this.width <= 530){
				this.isSP = true
			}else{
				this.isSP = false
			}
		},
	},
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'
import multidata from './module/multidata'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    multidata,
  },
})
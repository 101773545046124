<template>
	<transition name="backfade">
		<div id="loading" v-if="show" class="text-center"><img src="/images/loading.gif"></div>
	</transition>
</template>


<style>
#loading{
	background-color: #eee;
	top:0;
	left: 0;
	width: 100%;
	height: 300%;
	z-index: 9999;
	position: absolute;
}
.backfade-enter-active,
.backfade-leave-active{
	opacity: 1;
}
.backfade-enter,
.backfade-leave-to{
	opacity: 0;
	transition: opacity 1000ms;
}
</style>


<script>
export default{
	data(){
		return{
			show:this.$store.getters.get_loadstatus,
		}
	},
	mounted(){
		this.$store.watch((state, getters) => getters.get_loadstatus,
			(newValue) => {this.show = newValue})
	},
}
</script>
<template>
	<main role="main" class="mb-5">
		<loading></loading>
		<section class="container mt-3 mb-4" v-if="isSP">
			<search></search>
		</section>
		<section class="pickupBlock">
			<div class="container">
				<pickup :data="campList" />
			</div>
		</section>
		<section class="newsBlock">
			<div class="container">
				<div class="section-band">ニュース</div>
				<div class="row">
					<div class="col-12">
						<ul class="list-group-flush pl-0">
							<li class="list-group-item d-flex canClick" v-for="news in newsList" :key="news.index" @click="toArticle(news.id)">
								<a class="d-flex"><div class="mr-4 text-nowrap">{{news.date}}</div><div>{{news.title}}</div></a>
							</li>
						</ul>
					</div>
					<div class="col-12 col-md-9 mt-5 mx-auto">
						<button class="btn btn-color2 btn-block" @click="toNews()">ニュース一覧</button>
					</div>
				</div>
			</div>
		</section>
		<section class="productsBlock">
			<div class="container">
				<div class="section-band">新着商品</div>
				<div class="row">
					<div class="col-6 col-md-3" v-for="prd in prdList.slice(0,8)" :key="prd.index">
						<div class="canClick card mb-4 box-shadow" @click="toDetail(prd.variations[0].productCode)">
							<img class="card-img-top" :src="imageSet(prd.images)" data-holder-rendered="true">
							<div class="card-body">
								<p class="stock-status" :class="judgeStatus(prd.variations, prd.categories)"></p>
								<p class="card-title mb-0 main-point-color">{{prd.title}}</p>
								<p class="card-text d-block">
									{{setPrice(prd.variations)}}円
									<small class="text-nowrap">（税込）</small>
								</p>
							</div>
						</div>
					</div>
					<div class="col-12 mt-4 mx-auto row">
						<button class="col-12 col-md-9 btn btn-color2 btn-block mx-auto" @click="toProduct()">商品一覧</button>
					</div>
				</div>
			</div>
		</section>
		<section class="productsBlock">
			<div class="container">
				<div class="section-band">ピックアップ商品</div>
				<div class="row">
					<div class="col-6 col-md-3" v-for="prd in pickupList" :key="prd.index">
						<div class="canClick card mb-4 box-shadow" @click="toDetail(prd.variations[0].productCode)">
							<img class="card-img-top" :src="imageSet(prd.images)" data-holder-rendered="true">
							<div class="card-body">
								<p class="stock-status" :class="judgeStatus(prd.variations, prd.categories)"></p>
								<p class="card-title mb-0 main-point-color">{{prd.title}}</p>
								<p class="card-text d-block">
									{{setPrice(prd.variations)}}円
									<small class="text-nowrap">（税込）</small>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="worksBlock">
			<div class="container">
				<div class="section-band">作品一覧</div>
				<div class="row">
					<div class="col-6 col-md-3" v-for="work in workList" :key="work.index">
						<div class="canClick card mb-4 box-shadow" @click="toWork(work)">
							<img class="card-img-top" :src="work.imageUrl" data-holder-rendered="true">
							<div class="card-body">
								<p class="card-text">{{work.title}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="categoryBlock">
			<div class="container">
				<div class="section-band">商品種別</div>
				<div class="row mx-0 mx-md-2">
					<div class="col-12 col-md-6 mb-4 canClick" v-for="category in catList" :key="category.index" @click="toProduct(category)">
						<button class="btn">{{category.title}}</button>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
	import search from './searchBox'
	import loading from './loading.vue'
	import pickup from './pickupBlockComponent.vue'
	export default{
		data(){
			return{
				host:process.env.VUE_APP_IAM,
				prdList:[],
				pickupList:[],
				stkList:[],
				campList:[],
				workList:[],
				newsList:[],
				catList:[],
				isSP:false,
				now: new Date()
			}
		},
		components:{
			search,
			loading,
			pickup
		},
		created(){
			// scrollTo(0,0)
			document.title = 'ufotableWEBSHOP'
			this.$gtag.query('config', 'UA-196118927-1', {'page_path': '/', 'page_title': document.title})
			this.$gtag.query('config', 'G-S69VXZJE85', {'page_path': '/', 'page_title': document.title})
			this.$store.commit('DATA_LOAD', true)
			this.$store.commit('CHANGE_CATEGORY','0')
			this.$store.commit('CHANGE_SORT','0')
			this.$store.commit('CHANGE_ROOT','product')
			this.getData()
		},
		mounted(){
			this.handleResize()
			window.addEventListener('resize', this.handleResize)
			document.getElementById('scrollTarget').scrollIntoView(true)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.handleResize)
		},
		methods:{
			async getData(){
				try{
					const campaign = await this.$axios.get(this.$uri+'/image?key=campaign')
					const news = await this.$axios.get(this.$uri+'/news?$count=3')
					const products = await this.$axios.get(this.$uri+'/product')
					const stocks = await this.$axios.get(this.$uri+'/productStock')
					const works = await this.$axios.get(this.$uri+'/image?key=works')
					const category = await this.$axios.get(this.$uri+'/image?key=category')

					this.campList = campaign.data.images
					this.newsList = news.data.news
					this.prdList = products.data.products
					this.pickupList = products.data.products.filter((v)=>v.categories.some((n)=>n.groupName=='pickup'))
					this.stkList = stocks.data
					this.workList = works.data.images
					this.catList = category.data.images

					this.prdList.sort((a,b)=>{
						if(a.validAfter > b.validAfter) return -1
						return 0
					})

					this.pickupList.sort((a,b)=>{
						if(a.validAfter > b.validAfter) return -1
						return 0
					})
					this.endLoad()
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			toProduct(cat){
				if(cat === void 0){
					this.tagMove({path:'/products', params:{}})
				}else{
					let link = cat.linkUrl
					link = link.split('=')[1]
					this.tagMove({path:'/products?cat='+link, params:{'cat':link}})
				}
			},
			toNews(){
				this.tagMove({path:'/news', params:{}})
			},
			toArticle(id){
				this.tagMove({path:'/news/article/'+id, params:{'id':id}})
			},
			toDetail(code){
				this.tagMove({path:'/product/detail/'+code, params:{'hin':code}})
			},
			toWork(works){
				let name = works.linkUrl
				name = name.split('works/')[1]
				this.tagMove({path:'/works/'+name, params:{'name':name}})
			},
			tagMove(to){
				this.$router.push({'path':to.path, 'params':to.params})
			},
			imageSet(image_obj){
				if(image_obj[0] == void 0){
					return '/images/nowprinting3.gif'
				}else{
					return image_obj[0].url
				}
			},
			setPrice(v){
				let price = v.map((n)=> n.price)
				let highPrice = this.priceFilter(Math.max.apply(null, price))
				let rowPrice = this.priceFilter(Math.min.apply(null, price))
				if(highPrice == rowPrice){
					return rowPrice
				}else{
					return rowPrice+'～'+highPrice
				}
			},
			judgeStatus(v,categories){
				const release = new Date(v[0].releaseDate)
				let orderTo = new Date()
				if(v[0].reservationTo !== null){
					orderTo = new Date(v[0].reservationTo)
				}
				const customStatus = categories.filter((v)=>v.groupName == 'statusOption')

				const stockList = []
				v.forEach((variation)=> {
					let varFil = this.stkList.filter((c)=>c.productCode == variation.productCode)
					stockList.push(varFil)
				})
				let stockAvailable = 0
				stockList.forEach((s) => {
					if(s[0].available){
						stockAvailable++
					}
				})

				if(this.now > orderTo){
					return 'endofsale'
				}else if(stockAvailable == 0){
					return 'soldout'
				}else if(this.now > release){
					return 'exist'
				}else if(customStatus.length != 0){
					return customStatus[0].name
				}else if(this.now < release){
					return 'btoo'
				}else if(stockAvailable>0){
					return 'exist'
				}else{
					return 'soldout'
				}
			},
			handleResize() {
				this.width = window.innerWidth;
				if(this.width <= 530){
					this.isSP = true
				}else{
					this.isSP = false
				}
			},
			priceFilter(val){
				if (!val) return ''
				return val.toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,')
			},
			endLoad(){
				this.$store.commit('DATA_LOAD', false)
			},
		},
	}
</script>
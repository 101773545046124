<template>
	<div class="row">
		<div class="mx-auto col-12" :class="chkPath" v-for="camp in data" :key="camp.index" @click="toCampaign(camp.linkUrl)">
			<div class="card mb-4 box-shadow canClick">
				<img class="card-img-top" :src="camp.imageUrl" data-holder-rendered="true">
				<div class="card-body">
					<p class="card-text">{{camp.title}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			type: Array,
			default: () => {}
		}
	},
	computed: {
		chkPath () {
			if(this.$route.path == '/') {
				return ''
			} else {
				return 'col-md-6'
			}
		}
	},
	methods: {
		toCampaign(key){
			window.location.href = key
		}
	}
}
</script>
<template>
  <router-view></router-view>
</template>

<script>
  export default{
    watch:{
      '$route' :function(to,from){
        if(from.path.indexOf('works') != -1 && to.path.indexOf('works') == -1){
          if(document.body.getAttribute('id') != void 0){
            document.body.setAttribute('id','')
          }
        }
      }
    }
  }
</script>